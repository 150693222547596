.grid__item
	.button__title
		position: relative
		top: 2px
	&:hover
		.cat, .date, p
			color: #000 !important
		.hover_style
			.h6, .cat, .h3, .content, .h2, .autor, .h4
				transform: translate(10px, 10px)
			.date
				transform: translate(10px, -10px)
			a.h5, .button
				transform: translate(-10px, -10px)
			.button__title
				opacity: 1 !important
		.of-cover, .normal
			transform: scale(1.1)

	.of-cover, .normal
		transition: all .4s ease-in-out
		transform: scale(1)
	.of-cover
		height: 360px !important
	.hover-sin-imagen
		.hover_style
			&::after
				display: none

	.hover_style
		position: relative
		transition: all .2s ease-in-out
		&::after
			content: ''
			position: absolute
			height: 0%
			width: 100%
			top: 0
			left: 0
			z-index: -1
			transition: all .2s ease-in-out
		.content
			margin-top: 35px
		.cat, .date
			color: var(--color-gray-2)
			display: block
		a
			display: inline-block
		.autor
			display: block !important
		.h3
			margin: 8px 0px 4px 0px
			margin-right: 10px
		.h6
			margin: 0px
		.h4, .h5
			line-height: 1
		.cat, .date, .h6, .h4, .h5, .h3, .content, .button, .h2, .autor
			transition: all .2s ease-in-out
		.button
			&:hover
				.button__title
					color: rgba(#000, 0.6)
			.button__title
				opacity: 0

.grid__item
	&.sin-img
		.hover_style
			min-height: 250px
		.fix-top
			margin-top: 40px
		&.line
			&:after
				content: ''
				position: absolute
				width: calc( 100% - 40px )
				bottom: 0
				height: 1.5px
				left: 0
				right: 0
				margin: 0 auto
				background: var(--color-gray-1)
	&.con-img
		.fix-height
			min-height: 120px

		.fix-top
			margin-top: 30px
		.hover_style
			padding-top: 10px
		&.line
			border-bottom: 1.5px solid var(--color-gray-1)
		&:hover
			.hover_style
				&::after
					height: 100%
					transition: all .2s ease-in-out
	&.full
		.hover_style
			position: relative
			border-right: 6px solid
			padding-top: 10px
			padding-bottom: 10px
			.content, .h2
				padding-right: 60px
				@media screen and (max-width: $xxl)
					padding-right: 30px
				@media screen and (max-width: $xl)
					padding-right: 15px	
			&::after
				content: ""
				position: absolute
				height: 100%
				width: 0%
				top: 0
				right: 0
				left: auto
				z-index: -1
				transition: all 0.2s ease-in-out
		.of-cover
			min-height: 500px !important
		&:hover
			.cat, .date
				color: #000
			.hover_style
				&::after
					width: 100%
					transition: all .2s ease-in-out

		&.left
			.hover_style
				border-left: 6px solid
				border-right: none
				padding-left: 30px
				@media screen and (max-width: $xxl)
					padding-left: 15px
				@media screen and (max-width: $lg)
					padding-left: 5px	
				.content
					padding-right: 0px
				&::after
					left: 0
					right: auto

	&.papel
		border-right: 1.5px solid var(--color-gray-1)
		width: 50%
		&:hover
			.button
				transform: translate(-10px, 10px)
				margin-bottom: 40px

		&.grid__item_fluid-1
			padding: 40px 25px
			@media screen and (max-width: $xl)
				padding-top: 0px
		.hover_style
			padding-right: 40px
			@media screen and (max-width: $xl)
				padding-right: 15px
		> .position-relative
			&:before
				content: ''
				position: absolute
				height: 1.5px
				width: calc( 100% - 20px )1
				bottom: -40px
				left: 0px
				right: 0
				margin: 0 auto
				z-index: 99
				background: var(--color-gray-1)

.grid__item
	&.con-img, &.sin-img
		> .position-relative
			&:after
				content: ''
				position: absolute
				height: calc( 100% - 0px )
				top: 50%
				transform: translateY(-50%)
				width: 1.5px
				right: -20px
				background: var(--color-gray-1)
			&:before
				content: ''
				position: absolute
				height: calc( 100% - 0px )
				top: 50%
				transform: translateY(-50%)
				width: 1.5px
				left: -21.5px
				z-index: 99
				background: var(--color-gray-1)

.swiperRelacionadas
	overflow: hidden
	.swiper-pagination
		bottom: 0
	.swiper-wrapper
		margin-bottom: 40px
	.swiper-slide
		padding: 20px
		border-top: 1.5px solid var(--color-gray-1)
		border-bottom: 1.5px solid var(--color-gray-1)
		@media screen and (max-width: $sm)
			padding-left: 0px
			padding-right: 0px
	.position-relative::before, .position-relative::after
		@media screen and (max-width: $sm)
			display: none !important
	&:before
		content: ''
		position: absolute
		height: 100%
		width: 1.5px
		left: -1.5px
		z-index: 99
		background: #fff
	&:after
		content: ''
		position: absolute
		height: 100%
		width: 1.5px
		right: 0px
		top: 0px1
		z-index: 999
		background: #fff

.swiper-pagination-bullet-active
	background: #000

.js-grid
	&:after
		content: ''
		position: absolute
		height: 100%
		width: 20px
		right: 0px
		z-index: 99
		background: #fff
	&:before
		content: ''
		position: absolute
		height: 100%
		width: 20px
		left: 0px
		z-index: 99
		background: #fff
	.grid__item_fluid-1
		padding: 20px

.date
	font-size: 17px	!important
	line-height: 1.2 !important

.archive
	.cat
		display: none !important

.fix-papel
	display: flex
	flex-flow: row wrap
	position: relative
	.grid__item
		.button__title
			position: relative
			top: 4px
	&:before
		content: ''
		position: absolute
		height: 1.5px
		width: 100%
		bottom: 0px
		left: 0px
		z-index: 999
		display: block
		background: #fff
	&:after
		content: ""
		position: absolute
		height: 100%
		width: 20px
		right: 0px
		z-index: 99
		background: #fff

.grid_fluid-1
	margin:-20px !important
