.modal-open
	overflow: hidden
	.modal
		overflow-x: hidden
		overflow-y: auto

.modal
	position: fixed
	top: 0
	left: 0
	z-index: 6000
	display: none
	width: 100%
	height: 100%
	overflow: hidden
	outline: 0
	background: rgba(#fff, 0.8)
.modal__message
	font-size: 18px
.modal-content__wrapper-button
	text-align: center
	.button
		min-width: 200px

.modal__close
	position: absolute
	top: 20px
	right: 20px
	cursor: pointer
	z-index: 60
	@media only screen and (max-width: $xs)
		top: 10px
		right: 10px

.modal-dialog
	position: relative
	width: auto
	margin: 0
	pointer-events: none
.modal-dialog_container
	max-width: 980px !important

.modal
	&.fade .modal-dialog
		transition: -webkit-transform 0.3s ease-out
		transition: transform 0.3s ease-out
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
		-webkit-transform: translate(0, -50px)
		transform: translate(0, -50px)
	&.show .modal-dialog
		-webkit-transform: none
		transform: none

@media (prefers-reduced-motion: reduce)
	.modal.fade .modal-dialog
		transition: none

.modal-dialog-scrollable
	display: -ms-flexbox
	display: flex
	max-height: calc(100% - 1rem)
	.modal-content
		max-height: calc(100vh - 1rem)
		overflow: hidden
	.modal-header, .modal-footer
		-ms-flex-negative: 0
		flex-shrink: 0
	.modal-body
		overflow-y: auto

.modal-dialog-centered
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	min-height: calc(100% - 1rem)
	width: 100%
	&::before
		display: block
		height: calc(100vh - 1rem)
		content: ""
	&.modal-dialog-scrollable
		-ms-flex-direction: column
		flex-direction: column
		-ms-flex-pack: center
		justify-content: center
		height: 100%
		.modal-content
			max-height: none
		&::before
			content: none

.modal-content
	position: relative
	display: -ms-flexbox
	display: flex
	-ms-flex-direction: column
	flex-direction: column
	width: 100%
	pointer-events: auto
	background-clip: padding-box
	outline: 0
	padding: 3em
	min-height: 100vh
	@media only screen and (max-width: $lg)
		padding:  3em 2em
	
	@media only screen and (max-width: $xs)
		padding:  2em 1em

.modal-backdrop
	position: fixed
	top: 0
	left: 0
	z-index: 1040
	width: 100vw
	height: 100vh
	background-color: #000
	&.fade
		opacity: 0
	&.show
		opacity: 0.7

.modal-header
	display: flex
	align-items: center
	justify-content: space-between
	padding: 1rem 1rem
	border-bottom: 1px solid #dee2e6
	border-top-left-radius: 0.3rem
	border-top-right-radius: 0.3rem
	.close
		padding: 1rem 1rem
		margin: -1rem -1rem -1rem auto

.modal-title
	margin-bottom: 0
	line-height: 1.5

.modal-body
	position: relative
	-ms-flex: 1 1 auto
	flex: 1 1 auto
	padding: 0

.modal-footer
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	-ms-flex-pack: end
	justify-content: flex-end
	padding: 1rem
	border-top: 1px solid #dee2e6
	border-bottom-right-radius: 0.3rem
	border-bottom-left-radius: 0.3rem
	>
		\:not(:first-child)
			margin-left: .25rem
		\:not(:last-child)
			margin-right: .25rem

.modal-scrollbar-measure
	position: absolute
	top: -9999px
	width: 50px
	height: 50px
	overflow: scroll

@media (min-width: 576px)
	.modal-dialog
		max-width: 100vw
		margin: 0px
	.modal-dialog-scrollable
		max-height: calc(100% - 3.5rem)
		.modal-content
			max-height: calc(100vh - 3.5rem)
		&::before
			height: calc(100vh - 3.5rem)
	.modal-sm
		max-width: 300px

@media (min-width: 992px)
	.modal-lg, .modal-xl
		max-width: 800px
	.modal__message
		font-size: 24px

@media (min-width: 1200px)
	.modal-xl
		max-width: 1140px
