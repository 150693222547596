.single-post
	.content, #share
		max-width: 800px
		margin-left: auto
		margin-right: auto
		@media only screen and (max-width: $lg)
				max-width: calc( 100% -  50px )
	.img
		margin-left: auto
		margin-right: auto
		padding-left: 20px
		padding-right: 20px
		.lazy-wrapper
			margin: 0 auto	
			display: block
		p
			padding-top: 20px
			max-width: 600px
			margin: 0 auto

	.autor_imagen_banner
		position: absolute
		bottom: 0px
		width: 100%
		background: rgba(#fff, 0.7)
		z-index: 999
		
		p 
			margin: 5px
			font-weight: 500
				
			

	.intro
		.hover-zoom__inner
			border-bottom: 8px solid
	.publicidad
		padding-top: 30px
		padding-bottom: 30px
		.uno
			max-width: 700px
			margin: 0 auto
	.intro .of-cover, .intro .hover-zoom__inner
		min-height: 560px !important
	.section-slider-images.vertical
		max-width: 550px
		.slider__scrollbar_horizontal
			width: 400px
	.slider__wrapper-scrollbar	
		::before
			display: none


#share
	a.heateor_sss_whatsapp
		span
			width: 35px !important	
			height: 35px !important
			position: relative
			top: -2px
			left: -1px
	a.heateor_sss_button_linkedin
		span
			position: relative
			left: 2px