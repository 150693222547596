#page-header
	background: #fff
	.logo
		img
			@media only screen and (max-width: $lg)
				width: 110px
			@media only screen and (max-width: $md)
				width: 100px
			@media only screen and (max-width: $sm)
				width: 90px
	.menu
		margin: 0px !important
		.menu-item
			a
				font-weight: 500
				text-transform: uppercase
				font-size: 13px
				@media only screen and (max-width: $lg)
					font-size: 12.6px

	.h4
		line-height: 1
		text-align: right
		color: #000
		@media only screen and (max-width: $lg)
			font-size: 18px
	.logo-container
		padding-bottom: 12px
	.menu-container
		position: relative
		> div
			@media only screen and (max-width: $xl)
				padding-left: calc(var(--bs-gutter-x)/ 1)
		&::before
			content: ''
			width: calc( 100% - 30px)
			height: 1.5px
			background: var(--color-gray-1)
			position: absolute
			top: 0
			left: 0
			right: 0
			margin: 0 auto
		&::after
			content: ''
			width: calc( 100% - 30px)
			height: 1.5px
			background: var(--color-gray-1)
			position: absolute
			bottom: 0
			left: 0
			right: 0
			margin: 0 auto
			@media only screen and (max-width: $md)
				display: none
		.somos
			position: relative
			width: 155px
			display: flex
			justify-content: space-between
			padding-right: 22px
			padding-left: 5px
			@media only screen and (max-width: $xxl)
				width: 130px
			@media only screen and (max-width: $xl)
				display: none !important
			a:hover
				background-size: 0 !important
			&::after
				content: ''
				position: absolute
				right: 0px
				top: 7px
				height: 32px
				width: 1.5px
				display: block
				background: var(--color-gray-1)
			li:first-child a
				//margin-left: -15px !important

		.centro
			display: flex
			justify-content: space-between
			width: calc( 100% - 265px)
			padding-left: 40px
			padding-right: 40px
			@media only screen and (max-width: $xxl)
				width: calc( 100% - 230px)
				padding-left: 20px
				padding-right: 20px
			@media only screen and (max-width: $xl)
				width: calc( 100% - 100px)
				padding-left: 0px
				li:first-child
					a
						margin-left: -10px
			@media only screen and (max-width: $lg)
				width: 100%
				li:first-child
					a
						margin-left: -9px

		.buscador
			width: 110px
			position: relative
			text-align: right
			@media only screen and (max-width: $xxl)
				width: 100px
			@media only screen and (max-width: $lg)
				width: 40px
			a
				padding-right: 5px
				padding-left: 0px
			a:hover, a
				background-size: 0 !important
			&::after
				content: ''
				position: absolute
				left: 0
				top: 7px
				height: 32px
				width: 1.5px
				display: block
				background: var(--color-gray-1)
				@media only screen and (max-width: $lg)
					display: none
			span
				@media only screen and (max-width: $lg)
					display: none

.header_sticky
	.logo__wrapper-img
		img
			width: 100px

	h5
		display: none

.pt-header
	padding-top: 210px
	@media only screen and (max-width: $xl)
		padding-top: 200px
	@media only screen and (max-width: $md)
		padding-top: 110px

.pt2-header
	padding-top: 150px
	@media only screen and (max-width: $lg)
		padding-top: 140px
	@media only screen and (max-width: $md)
		padding-top: 90px

.pt3-header
	padding-top: 180px
	@media only screen and (max-width: $xl)
		padding-top: 160px
	@media only screen and (max-width: $lg)
		padding-top: 90px

.border-bottom
	border-bottom: 1.5px solid var(--color-gray-1) !important

.black
	color: #000 !important

.gray
	color: var(--color-gray-3) !important

.fw-500
	font-weight: 500 !important

.fw-400
	font-weight: 400 !important
.fw-300
	font-weight: 300 !important

#page-footer
	.h3, p, a, small
		color: var(--color-gray-3)
		font-weight: 500
	p
		line-height: 1.1

	.social
		max-width: 200px
		margin: 0 auto
		display: inline
		a
			font-size: 14px
			text-transform: uppercase
			font-weight: 500
			border-bottom: 1px solid var(--color-gray-3)
			line-height: 1.2
			color: var(--color-gray-3)
			margin: 0px 15px
			@media only screen and (max-width: $xs)
				font-size: 13px

.menu-overlay
	.h3
		text-transform: uppercase
		color: #000 !important
	.border-top
		border-top: 1.5px solid var(--color-gray-1) !important
		width: calc( 100% - 20px )

.header__wrapper-overlay-menu
	.header__menu-column
		&::after
			content: ''
			width: 100%
			position: absolute
			left: 0
			height: 62px
			background: #fff
			top: 0

.pdf
	.padding
		padding-left: 80px
		@media screen and (max-width: $xl)
			padding-left: 40px
		@media screen and (max-width: $lg)
			padding-left: 20px
	.nav
		margin-top: 10px
		border-top: 1px solid var(--color-gray-1)
		border-bottom: 1px solid var(--color-gray-1)
		padding: 10px 0px
		.button__title
			padding: 0px 4px
		.next

			.button__icon
				transform: rotate(180deg)
			.button
				position: relative
				left: -5px
				&:hover
					.button__label-normal
						.button__title
							transform: translate(0%, -100%)
							opacity: 0
						.button__icon
							opacity: 0
							transform: translate(100%, 0%) rotate(180deg)
					.button__label-hover
						.button__title
							transform: translate(0%, 0%)
							opacity: 1
						.button__icon
							opacity: 1
							transform: translate(0%, 0%) rotate(180deg)

		.button__title
			font-size: 15px
			@media screen and (max-width: $lg)
				font-size: 14px
		.button.circle .button__icon
			width: 25px
			height: 25px
			line-height: 25px
		.button.circle .button__icon::before
			margin-left: -5px
			font-size: 10px

.logo-facultar
	position: absolute
	width: 140px
	bottom: 20px
	@media only screen and (max-width: $lg)
		position: relative
		margin: 0 auto
		width: 130px
		bottom: 0
		margin-bottom: 20px

.mailster
	width: 360px
	margin-top: 10px
	@media only screen and (max-width: $xl)
		width: 100%
	::placeholder
		font-size: 14px
		text-transform: uppercase
	.mailster-form-fields
		display: flex
	label
		display: none
	.mailster-wrapper	
		width: 100%
		input
			width: 100%
			border: none
			font-size: 14px
			color: #fff
			background: #000
			border-bottom: 1px solid #fff
			opacity: 1
			padding: 2px 0px
	.form-submit
		width: 30px
		input
			font-size: 0px	
			height: 30px
			width: 30px
			line-height: 40px
			background: url('../img/flecha_der_negra.png'), #fff
			background-size: 20px 14px
			background-repeat: no-repeat
			background-position: center center
			border: none
			border-radius: 50%
			position: relative
			top: -3px
			margin-left: 10px
	.mailster-form-info
		background: transparent !important
		padding: 0
		font-size: 13px
		color: #fff
		p
			font-size: 13px
			color: #fff !important
			



.mailchimp
	margin-top: 10px
	.mc4wp-form-fields
		width: 360px
		line-height: 20px
	label
		height: 30px
		width: calc( 100% - 40px)
		padding-right: 10px
		line-height: 30px
		input
			width: 100%
			border: none
			font-size: 14px
			color: #fff
			background: #000
			border-bottom: 1px solid #fff
			text-transform: uppercase
			opacity: 1
			::placeholder
				color: #fff

	.btn
		height: 30px
		width: 30px
		line-height: 40px
		background: url('../img/flecha_der_negra.png'), #fff
		background-size: 20px 14px
		background-repeat: no-repeat
		background-position: center center
		border: none
		border-radius: 50%
		position: relative
		top: 10px


.animacion
	position: relative
	width: fit-content
	ul
		padding: 0
		margin: 0
	li
		list-style: none
		width: 100%
		white-space: nowrap
		text-align: right
	.ul1 li
		animation: anima1 3s alternate infinite
	.ul2
		position: absolute
		top: 0
		left: 100%
	.ul2 li
		animation: anima2 3s alternate infinite

@keyframes anima1
	0%
		width: 120%
		opacity: 1
	50%
		width: 120%
		opacity: 1
	5%
		width: 50%
		opacity: 0
	100%
		width: 50%
		opacity: 0

@keyframes anima2
	0%
		transform: translateX(100%)
		width: 50%
		opacity: 0
	40%
		transform: translateX(100%)
		width: 50%
		opacity: 0

	65%
		transform: translateX(-100%)
		width: 100%
		opacity: 1
	100%
		transform: translateX(-100%)
		width: 100%
		opacity: 1

.page-content
	.mailster-email
		text-align: center
	.submit-button
		margin-top: 20px
		background: #000
		color: #fff
		border: none
		border-radius: 0
		padding:10px		