html
	font-size: $em-base
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeSpeed
	-webkit-text-rendering: optimizeSpeed
	-moz-osx-font-smoothing: grayscale
	font-kerning: none
	scroll-behavior: auto !important
html.smoothscroll
	body
		overflow-y: hidden !important
		overflow-x: hidden !important
body
	width: 100%
	min-width: 320px
	font-family: var(--font-primary)
	line-height: var(--paragraph-line-height)
	color: var(--paragraph-color-dark)
	font-size: 100%
	word-wrap: break-word
	overflow-wrap: anywhere
	overflow-x: hidden
img, iframe
	max-width: 100%
img
	height: auto
.page-wrapper__content
	position: relative
	overflow: hidden
	z-index: 50
a
	text-decoration: none
	color: $color-dark-1
	+trans1
	&:hover, &:focus
		text-decoration: none
	&:hover
		color: $color-dark-1
.body_lock-scroll
	overflow: hidden
	position: fixed
	position: sticky
	top: 0
	left: 0
	width: 100%
	height: 100%
.container-fluid
	padding-left: var(--gutter-horizontal, 120)
	padding-right: var(--gutter-horizontal, 120)

.container-fluid_paddings
	padding-top: var(--gutter-horizontal, 120)
	padding-bottom: var(--gutter-horizontal, 120)

.container-fluid_default
	padding-left: 20px
	padding-right: 20px
.arts-is-dragging
	cursor: none
	// pointer-events: none !important
	// .pointer-events-auto, .slider-projects__slide.swiper-slide-active
	// 	pointer-events: none !important
	// 	*
	// 		pointer-events: none !important
	// *
	// 	pointer-events: none !important
#page-wrapper
	overflow-x: hidden
	background-color: #fff

@media screen and (max-width: $md)
	.container_p-md-0
		padding-top: 0
		padding-right: 0
		padding-bottom: 0
		padding-left: 0

	.container_px-md-0
		padding-left: 0
		padding-right: 0
		> .row
			margin-left: 0
			margin-right: 0
			> [class*="col-"]
				padding-left: 0
				padding-right: 0

	.container_py-md-0
		padding-top: 0
		padding-bottom: 0

@media screen and (max-width: $sm)
	.container_p-sm-0
		padding-top: 0
		padding-right: 0
		padding-bottom: 0
		padding-left: 0

	.container_px-sm-0
		padding-left: 0
		padding-right: 0
		> .row
			margin-left: 0
			margin-right: 0
			> [class*="col-"]
				padding-left: 0
				padding-right: 0

	.container_py-sm-0
		padding-top: 0
		padding-bottom: 0

@media screen and (max-width: $xs)
	.container_p-xs-0
		padding-top: 0
		padding-right: 0
		padding-bottom: 0
		padding-left: 0

	.container_px-xs-0
		padding-left: 0
		padding-right: 0
		> .row
			margin-left: 0
			margin-right: 0
			> [class*="col-"]
				padding-left: 0
				padding-right: 0
	.container_py-xs-0
		padding-top: 0
		padding-bottom: 0
