#home-slider
	.h1
		text-transform: uppercase
	.section-fullheight__inner, .swiper-slide
		height: 580px
		min-height: 580px
		@media screen and (max-width: $xxl)
			height: 540px
			min-height: 540px
		@media screen and (max-width: $xl)
			height: 500px
			min-height: 500px
		@media screen and (max-width: $lg)
			height: 480px
			min-height: auto
		@media screen and (max-width: $md)

	.overlay.fix
		width: calc( 100% - 40px)
		height: calc( 100% - 5px)
		left: 20px
		z-index: -1
	.politica
		border-bottom: 5px solid var(--politica)
	.button.circle
		.button__icon
			width: 32px
			height: 32px
			line-height: 32px
			&::before
				margin-left: -3px
		.button__title
			font-size: 16px
			padding-right: 5px
			@media screen and (max-width: $lg)
				font-size: 15px
			@media screen and (max-width: $xs)
				font-size: 14px	


.home_1
	.grid__item:nth-child(3).sin-img.line:before, .grid__item:nth-child(3).sin-img.line:after
		@media screen and (max-width: $xl)
			display: none
	.grid__item_tablet-6
		@media screen and (max-width: $xl)
			max-width: 100% !important

	.grid__item.sin-img.line:after
		width: 100%
	.grid__item.sin-img.line:before
		content: ""
		position: absolute
		width: 100%
		top: 0
		height: 1.5px
		left: 0
		right: 0
		margin: 0 auto
		background: var(--color-gray-1)

.fix-home
	background: var(--color-gray-4)
	padding-top: 1px
	padding-bottom: 1px
	max-width: 1450px
	.grid__item
		max-width: 800px
		margin: 0 auto
		.position-relative
			.content
				margin-top: 60px
			.hover_style
				padding: 40px 40px
			.button.circle .button__icon::before
				margin-left: -4px
		.fix-top
			margin-top: 80px !important



.home-feature
	&:before
		content: ''
		position: absolute
		height: 100%
		width: 20px
		top: 0px
		left: 0px
		z-index: 999
		display: block
		background: #fff
	&:after
		content: ''
		position: absolute
		height: 100%
		width: 20px
		top: 0px
		right: 0px
		z-index: 999
		display: block
		background: #fff	