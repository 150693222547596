#buscador


	.modal__close
		.h5

			margin-right: 5px
	.search-form
		display: flex
		@media only screen and (max-width: $lg)
			width: 100%
		
		
		
		.search-field
			border: none
			border-bottom: 2px solid #000
			background: transparent
			width: 600px
			padding: 4px 0px
			font-size: 18px
			@media only screen and (max-width: $lg)
				width: 100%
		label
			@media only screen and (max-width: $lg)
				width: 100%

				
		::placeholder
			font-size: 22px
			text-transform: uppercase
			font-weight: 500
			color: #000
			@media only screen and (max-width: $lg)
				font-size: 20px
			@media only screen and (max-width: $md)	
				font-size: 19px
			@media only screen and (max-width: $xs)
				font-size: 18px	
		.search-submit
			border: 1px solid #000
			background: transparent
			padding-left: 14px
			padding-right: 14px
			margin-left: 20px
			font-weight: 500
			font-size: 15px
			text-transform: uppercase
			transition: all 0.2s ease-in-out
			@media only screen and (max-width: $md)
				padding-left: 10px
				padding-right: 10px
				font-size: 14px	
			@media only screen and (max-width: $xs)
				font-size: 13px		
				margin-left: 10px	
				padding-left: 6px
				padding-right: 6px
				
				
			&:hover
				background: #000
				color: #fff



input:focus, textarea:focus, select:focus
	outline: none

	
