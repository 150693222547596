#page-header
	.menu-item.politica a
		background-image: linear-gradient(var(--politica), var(--politica))
	.menu-item.historia a
		background-image: linear-gradient(var(--historia), var(--historia))
	.menu-item.literatura a
		background-image: linear-gradient(var(--literatura), var(--literatura))
	.menu-item.ciencias a
		background-image: linear-gradient(var(--ciencia), var(--ciencia))
	.menu-item.filosofia a
		background-image: linear-gradient(var(--filosofia), var(--filosofia))
	.menu-item.arte a
		background-image: linear-gradient(var(--arte), var(--arte))
	.menu-item.musica a
		background-image: linear-gradient(var(--musica), var(--musica))
	.menu-item.medioambiente a
		background-image: linear-gradient(var(--medioambiente), var(--medioambiente))
	.menu-item.tecnologia a
		background-image: linear-gradient(var(--tecnologia), var(--tecnologia))	
	.menu-item.linguistica a
		background-image: linear-gradient(var(--linguistica), var(--linguistica))	
		


		

.grid__item.politica.con-img .hover_style::after
	background: var(--politica)

.grid__item.politica.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--politica)

.grid__item.politica.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#d9d9d9 , 0.5)


.grid__item.full.politica:hover .hover_style::after
	background: var(--politica)

.grid__item.full.politica .hover_style
	border-color: var(--politica) !important

.grid__item.politica.sin-img:hover .hover_style
	background: var(--politica)

.single-post .intro.politica .hover-zoom__inner
	border-color: var(--politica)	

	


.grid__item.historia.con-img .hover_style::after
	background: var(--historia)

.grid__item.historia.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--historia)

.grid__item.historia.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#FD9AA2 , 0.5)

.grid__item.full.historia:hover .hover_style::after
	background: var(--historia)

.grid__item.full.historia .hover_style
	border-color: var(--historia) !important

.grid__item.historia.sin-img:hover .hover_style
	background: var(--historia)

.single-post .intro.historia .hover-zoom__inner
	border-color: var(--historia)	



.grid__item.literatura.con-img .hover_style::after
	background: var(--literatura)

.grid__item.literatura.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--literatura)

.grid__item.literatura.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#FEDAC0 , 0.5)

.grid__item.full.literatura:hover .hover_style::after
	background: var(--literatura)

.grid__item.full.literatura .hover_style
	border-color: var(--literatura) !important

.grid__item.literatura.sin-img:hover .hover_style
	background: var(--literatura)

.single-post .intro.literatura .hover-zoom__inner
	border-color: var(--literatura)		



.grid__item.ciencias.con-img .hover_style::after
	background: var(--ciencia)

.grid__item.ciencias.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--ciencia)

.grid__item.ciencias.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#F7F392, 0.5)

.grid__item.full.ciencias:hover .hover_style::after
	background: var(--ciencia)

.grid__item.full.ciencias .hover_style
	border-color: var(--ciencia) !important

.grid__item.ciencias.sin-img:hover .hover_style
	background: var(--ciencia)

.single-post .intro.ciencias .hover-zoom__inner
	border-color: var(--ciencia)	


.grid__item.filosofia.con-img .hover_style::after
	background: var(--filosofia)

.grid__item.filosofia.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--filosofia)

.grid__item.filosofia.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#D1D8F2, 0.5)


.grid__item.full.filosofia:hover .hover_style::after
	background: var(--filosofia)

.grid__item.full.filosofia .hover_style
	border-color: var(--filosofia) !important

.grid__item.filosofia.sin-img:hover .hover_style
	background: var(--filosofia)

.single-post .intro.filosofia .hover-zoom__inner
	border-color: var(--filosofia)	


.grid__item.arte.con-img .hover_style::after
	background: var(--arte)

.grid__item.arte.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--arte)

.grid__item.arte.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#E2F0CC, 0.5)

.grid__item.full.arte:hover .hover_style::after
	background: var(--arte)

.grid__item.full.arte .hover_style
	border-color: var(--arte) !important

.grid__item.arte.sin-img:hover .hover_style
	background: var(--arte)

.single-post .intro.arte .hover-zoom__inner
	border-color: var(--arte)	


.grid__item.musica.con-img .hover_style::after
	background: var(--musica)

.grid__item.musica.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--musica)

.grid__item.musica.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#F2B6EA, 0.5)

.grid__item.full.musica:hover .hover_style::after
	background: var(--musica)

.grid__item.full.musica .hover_style
	border-color: var(--musica) !important

.grid__item.musica.sin-img:hover .hover_style
	background: var(--musica)

.single-post .intro.musica .hover-zoom__inner
	border-color: var(--musica)		


.grid__item.medio-ambiente.con-img .hover_style::after
	background: var(--medioambiente)

.grid__item.medio-ambiente.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--medioambiente)

.grid__item.medio-ambiente.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#B5EAD6, 0.5)

.grid__item.full.medio-ambiente:hover .hover_style::after
	background: var(--medioambiente)

.grid__item.full.medio-ambiente .hover_style
	border-color: var(--medioambiente) !important

.grid__item.medio-ambiente.sin-img:hover .hover_style
	background: var(--medioambiente)

.single-post .intro.medio-ambiente .hover-zoom__inner
	border-color: var(--medioambiente)			


.grid__item.tecnologia.con-img .hover_style::after
	background: var(--tecnologia)

.grid__item.tecnologia.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--tecnologia)

.grid__item.tecnologia.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#B3C9EF , 0.5)

.grid__item.full.tecnologia:hover .hover_style::after
	background: var(--tecnologia)

.grid__item.full.tecnologia .hover_style
	border-color: var(--tecnologia) !important

.grid__item.tecnologia.sin-img:hover .hover_style
	background: var(--tecnologia)

.single-post .intro.tecnologia .hover-zoom__inner
	border-color: var(--tecnologia)		


.grid__item.linguistica.con-img .hover_style::after
	background: var(--linguistica)

.grid__item.linguistica.con-img .hover-imagen .mask-reveal__layer-1
	border-bottom: 6px solid var(--linguistica)

.grid__item.linguistica.con-img .hover-sin-imagen .mask-reveal__layer-1
	background: rgba(#C0E4FE , 0.5)

.grid__item.full.linguistica:hover .hover_style::after
	background: var(--linguistica)	

.grid__item.full.linguistica .hover_style
	border-color: var(--linguistica) !important

.grid__item.linguistica.sin-img:hover .hover_style
	background: var(--linguistica)	

.single-post .intro.linguistica .hover-zoom__inner
	border-color: var(--linguistica)			



	

	